import { createSlice } from '@reduxjs/toolkit'
import createCarBuilder from './builders/createCar.builder'
import { initialCarFirstData, initialCarMainData } from './addNewCar.helpers'
import getCarDetailsFromVINProviderBuilder from './builders/getCarDetailsFromVINProvider.builder'
import getInitialMakeAndModelBuilder from './builders/getInitialMakeAndModel.builder'
import attachAppraisalDocumentBuilder from './builders/attachAppraisalDocument.builder'
import createCarOperatorBuilder from './builders/createCarOperator.builder'

const initialState = {
  isLoading: false,
  error: null,
  carCreatedId: null,
  createCarData: null,
  carFirstData: initialCarFirstData,
  carMainData: initialCarMainData,
  carSpecsOptions: null,
  vinProvider: {
    isLoading: false,
    error: null,
    data: null,
  },
  policy: null,
}

export const addNewCarReducer = createSlice({
  name: 'addCar',
  initialState: initialState,
  reducers: {
    setRviCarPolicy: (state, action) => {
      state.policy = action.payload
      const details = action.payload.details
      state.carFirstData.vin.value = details.vin
      state.carFirstData.vin.valid = true
      state.carMainData.mileage = details?.mileage
      state.carMainData.firstRegistrationDate = details?.firstRegistrationDate
      state.carMainData.exteriorColor = details?.color
      state.carFirstData.make = {
        id: details.make?.['@id'],
        name: details.make?.name,
      }
      state.carFirstData.model = {
        id: details.model?.['@id'],
        name: details.model?.name,
      }
    },
    setRviCarCompany: (state, action) => {
      state.carFirstData.company = action.payload
    },
    setVin: (state, action) => {
      state.carFirstData.vin = action.payload
      state.carFirstData.valid = !!(
        state.carFirstData.vin.valid &&
        state.carFirstData.make.id &&
        state.carFirstData.model.id
      )
    },
    setMake: (state, action) => {
      state.carFirstData.make = action.payload
      if (action.payload.id === null || action.payload.name === '') {
        console.log(action.payload)
        state.carFirstData.model.name = ''
        state.carFirstData.model.id = null
      }
      state.carFirstData.valid = !!(
        state.carFirstData.vin.valid &&
        state.carFirstData.make.id &&
        state.carFirstData.model.id
      )
    },
    setModel: (state, action) => {
      state.carFirstData.model = action.payload
      if (action.payload.name === '') {
        state.carFirstData.model.id = null
      }
      state.carFirstData.valid = !!(
        state.carFirstData.vin.valid &&
        state.carFirstData.make.id &&
        state.carFirstData.model.id
      )
    },
    setCarCreatedId: (state, action) => {
      state.carCreatedId = action.payload
    },
    setCarMainData: (state, action) => {
      state.carMainData = action.payload
    },
    resetCarData: (state) => {
      state.car = initialState.car
      state.carCreatedId = initialState.carCreatedId
      state.carFirstData = initialState.carFirstData
      state.carMainData = initialState.carMainData
      state.carSpecsOptions = initialState.carSpecsOptions
      state.vinProvider = initialState.vinProvider
      state.policy = initialState.policy
    },
    setFirstDataValid: (state) => {
      state.carFirstData.valid = true
    },
  },
  extraReducers: (builder) => {
    createCarBuilder(builder)
    getCarDetailsFromVINProviderBuilder(builder)
    getInitialMakeAndModelBuilder(builder)
    attachAppraisalDocumentBuilder(builder)
    createCarOperatorBuilder(builder)
  },
})

export const {
  setVin,
  setMake,
  setModel,
  setCarCreatedId,
  setCarMainData,
  resetCarData,
  setFirstDataValid,
  setRviCarCompany,
  setRviCarPolicy,
} = addNewCarReducer.actions

export default addNewCarReducer.reducer
