import React, { useState } from 'react'
import { Box, Stack } from '@mui/material'
import {
  AddRounded,
  RefreshRounded,
  FilterListRounded,
  SearchRounded,
  FilterListOffRounded,
} from '@mui/icons-material'
import UITextDisplaySmall from '../UIText/UITextDisplaySmall'
import { colorsConfig } from '../../config/themeConfig'
import TextFieldSmallEdible from '../TextFieldSmallEdible'
import UIIconButton from '../UIButtons/UIIconButton'
import UITextLabelMedium from '../UIText/UITextLabelMedium'
import UITextLabelLarge from '../UIText/UITextLabelLarge'
import UITextLabelExtraLarge from '../UIText/UITextLabelExtraLarge'
import SearchInput from '../../components/Search/SearchInput'

const UITableControls = ({
  title,
  onAdd,
  onRefresh,
  searchValue,
  setSearchValue,
  viewMode,
  onViewModeChange,
  filters,
  addText = 'Add',
  refreshText = 'Refresh',
  showFiltersText = 'Show filters',
  hideFiltersText = 'Hide filters',
}) => {
  const [filtersOpen, setFiltersOpen] = useState(false)

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" gap="16px">
        <UITextLabelExtraLarge fontWeight="700" text={title} sx={{ whiteSpace: 'nowrap' }} />
        {setSearchValue && (
          <SearchInput
            label="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        )}
      </Stack>
      <Stack direction="row" gap="20px" alignItems="center">
        {filters && filtersOpen ? filters : null}
        <Stack direction="row" alignItems="center" gap="4px">
          {/*{onViewModeChange && viewMode && (*/}
          {/*  <UIViewModeButton value={viewMode} setValue={(value) => onViewModeChange(value)} />*/}
          {/*)}*/}
          {onAdd && (
            <UIIconButton text={addText} onClick={() => onAdd()}>
              <AddRounded />
            </UIIconButton>
          )}
          {onRefresh && (
            <UIIconButton text={refreshText} onClick={() => onRefresh()}>
              <RefreshRounded />
            </UIIconButton>
          )}
          {filters && (
            <UIIconButton
              text={filtersOpen ? hideFiltersText : showFiltersText}
              onClick={() => setFiltersOpen(!filtersOpen)}
            >
              {filtersOpen ? <FilterListOffRounded /> : <FilterListRounded />}
            </UIIconButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default UITableControls
