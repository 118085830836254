import axios from 'axios'
import { API_BASE_URL } from '../config/default'

export const $api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const apiConstants = {
  hydraMember: 'hydra:member',
  hydraDescription: 'hydra:description',
  hydraTotal: 'hydra:totalItems',
}

export const putHeaders = {
  'Content-Type': 'application/ld+json',
}

export const patchHeaders = {}

export const multiPartHeaders = {
  'Content-Type': 'multipart/form-data',
}

export const filterParams = (params) => {
  return Object.keys(params)
    .filter((key) => params[key] !== null && params[key] !== undefined && params[key] !== '')
    .reduce((obj, key) => {
      obj[key] = params[key]
      return obj
    }, {})
}

export const getApiError = (e, placeholder = 'Unknown error') => {
  return e?.response?.data?.[apiConstants.hydraDescription] ?? placeholder
}
