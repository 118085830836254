import React from 'react'
import { Box } from '@mui/material'
import { colorsConfig } from '../../config/themeConfig'

const UIPaper = ({ sx, children, ...props }) => {
  return (
    <Box
      sx={{
        p: '30px',
        borderRadius: '8px',
        background: colorsConfig.white,
        boxShadow: '0 0 24px 0 rgba(0,0,0,.1)',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export default UIPaper
