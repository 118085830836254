import iconHome from '../assests/img/icons/icon-home.svg'
import iconVirtualGarage from '../assests/img/icons/icon-virtual-garage.svg'
import iconAuctions from '../assests/img/icons/icon-auctions.svg'
import iconFinancing from '../assests/img/icons/icon-financing.svg'
import iconInsurance from '../assests/img/icons/icon-insurance.svg'
import iconCarWarranty from '../assests/img/icons/icon-warranty.svg'
import iconCarSubscription from '../assests/img/icons/icon-car-subscription.svg'
import iconStockFinancing from '../assests/img/icons/icon-stock-financing.svg'
import iconAppraisal from '../assests/img/icons/icon-appraisal.svg'
import iconCoins from '../assests/img/icons/icon-coins.svg'
import iconSignUp from '../assests/img/icons/icon-sign-up.svg'
import iconBank from '../assests/img/icons/icon-bank.svg'
import iconLoanProgram from '../assests/img/icons/icon-loan-program.svg'
import iconProductType from '../assests/img/icons/icon-product-type.svg'
import iconProduct from '../assests/img/icons/icon-product.svg'
import iconMarketplace from '../assests/img/icons/icon-marketplace.svg'
import iconRVI from '../assests/img/icons/icon-rvi.svg'
import {
  Group,
  Article,
  CarCrash,
  AirlineSeatReclineExtra,
  CarRepair,
  AccountBalanceRounded,
  Security,
  ViewListRounded,
  GridViewRounded,
  AssuredWorkloadRounded,
  PeopleRounded,
  TableChartRounded,
  BusinessRounded,
} from '@mui/icons-material'

export const menuConfig = [
  {
    id: 1,
    title: 'Dashboard',
    icon: iconHome,
    link: '/dashboard',
    trans: 'sidebar_dashboard',
  },
  {
    id: 2,
    title: 'Virtual Garage',
    icon: iconVirtualGarage,
    link: '/virtual-garage',
    trans: 'sidebar_virtual_garage',
  },
  {
    id: 10,
    title: 'Marketplace',
    icon: iconMarketplace,
    link: '/marketplace',
    comingSoon: true,
    trans: 'sidebar_marketplace',
  },
  {
    id: 3,
    title: 'Appraisal',
    icon: iconAppraisal,
    link: '/appraisal',
    trans: 'sidebar_appraisal',
  },
  {
    id: 4,
    title: 'Auctions',
    icon: iconAuctions,
    link: '/auctions',
    addons: 'auctionCarsAmount',
    trans: 'sidebar_auctions',
  },
  {
    id: 5,
    title: 'Financing',
    icon: iconFinancing,
    link: '/financing',
    trans: 'sidebar_financing',
  },
  {
    id: 6,
    title: 'Insurance',
    icon: iconInsurance,
    link: '/insurance',
    trans: 'sidebar_insurance',
  },
  {
    id: 7,
    title: 'RV Insurance',
    muiIcon: (
      <AssuredWorkloadRounded
        sx={{ fill: 'white', fontSize: '17px', mr: '7px', position: 'relative', ml: '-1px' }}
      />
    ),
    link: '/rvi',
    trans: 'sidebar_rvi',
  },
  {
    id: 8,
    title: 'Car Warranty',
    icon: iconCarWarranty,
    link: '/car-warranty',
    trans: 'sidebar_car_warranty',
  },

  {
    id: 9,
    title: 'Car Subscription',
    icon: iconCarSubscription,
    link: '/car-subscription',
    trans: 'sidebar_car_subscription',
    comingSoon: true,
  },
  {
    id: 10,
    title: 'Stock Financing',
    icon: iconStockFinancing,
    link: '/stock-financing',
    trans: 'sidebar_stock_financing',
    comingSoon: true,
  },
]

export const operatorMenuConfig = [
  {
    id: 1,
    title: 'Dashboard',
    icon: iconHome,
    link: '/operator/dashboard',
  },
  {
    id: 2,
    title: 'Appraisals',
    icon: iconAppraisal,
    link: '/operator/appraisals',
  },
  {
    id: 3,
    title: 'Auctions',
    icon: iconAuctions,
    link: '/operator/auctions',
  },
  {
    id: 4,
    title: 'Finance requests',
    icon: iconFinancing,
    link: '/operator/finance-requests',
  },
  {
    id: 5,
    title: 'Insurance requests',
    icon: iconInsurance,
    link: '/operator/insurance-requests',
  },
  {
    id: 6,
    title: 'Sign up requests',
    icon: iconSignUp,
    link: '/operator/sign-up-requests',
  },
  {
    id: 7,
    title: 'Coins requests',
    icon: iconCoins,
    link: '/operator/coins-requests',
  },
  {
    id: 8,
    title: 'RV Insurance',
    muiIcon: (
      <AssuredWorkloadRounded
        sx={{ fill: 'white', fontSize: '17px', mr: '7px', position: 'relative', ml: '-1px' }}
      />
    ),
    link: '/operator/rvi',
    trans: 'sidebar.rvi',
  },
  {
    id: 9,
    title: 'Companies',
    muiIcon: (
      <PeopleRounded
        sx={{ fill: 'white', fontSize: '20px', mr: '5px', position: 'relative', ml: '-1px' }}
      />
    ),
    link: '/operator/companies',
    // trans: 'sidebar_companies',
  },
]

export const expertMenuConfig = [
  {
    id: 1,
    title: 'Dashboard',
    icon: iconHome,
    link: '/expert/dashboard',
  },
  {
    id: 2,
    title: 'Appraisals',
    icon: iconAppraisal,
    link: '/expert-panel',
  },
]

export const adminMenuConfig = [
  {
    id: 7,
    title: 'Companies',
    icon: null,
    muiIcon: <BusinessRounded sx={{ fill: 'white', fontSize: '20px', mr: '5px' }} />,
    link: '/admin/companies',
  },
  {
    id: 1,
    title: 'Managers',
    icon: null,
    muiIcon: <Group sx={{ fill: 'white', fontSize: '20px', mr: '5px' }} />,
    link: '/admin/users',
  },
  {
    id: 2,
    title: 'Coin pricings',
    icon: iconCoins,
    link: '/admin/coin-pricings',
  },
  {
    id: 3,
    title: 'Appraisal',
    icon: iconAppraisal,
    children: [
      {
        id: 1,
        title: 'Documents',
        link: '/admin/appraisal/documents',
        icon: null,
        muiIcon: <Article sx={{ fill: 'white', fontSize: '20px', mr: '5px' }} />,
      },
      // {
      //     id: 2,
      //     title: 'Photos',
      //     link: '/admin/appraisal/photos',
      //     icon: null,
      //     muiIcon: <Photo sx={{fill: 'white', fontSize: '20px', mr: '5px'}} />
      // },
      {
        id: 3,
        title: 'Car parts & Damages',
        link: '/admin/appraisal/damages',
        icon: null,
        muiIcon: <CarCrash sx={{ fill: 'white', fontSize: '20px', mr: '5px' }} />,
      },
      // {
      //     id: 4,
      //     title: 'Warning lights',
      //     link: '/admin/appraisal/warning-lights',
      //     icon: null,
      //     muiIcon: <LightbulbCircle sx={{fill: 'white', fontSize: '20px', mr: '5px'}} />
      // },
      {
        id: 5,
        title: 'Interior conditions',
        link: '/admin/appraisal/interior-conditions',
        icon: null,
        muiIcon: <AirlineSeatReclineExtra sx={{ fill: 'white', fontSize: '20px', mr: '5px' }} />,
      },
      {
        id: 6,
        title: 'Mechanical conditions',
        link: '/admin/appraisal/mechanical-conditions',
        icon: null,
        muiIcon: <CarRepair sx={{ fill: 'white', fontSize: '20px', mr: '5px' }} />,
      },
    ],
  },
  {
    id: 4,
    title: 'Financing',
    icon: iconFinancing,
    link: '/admin/financing',
    children: [
      {
        title: 'Banks',
        icon: iconBank,
        link: '/admin/financing/banks',
      },
      {
        title: 'Loan programs',
        icon: iconLoanProgram,
        link: '/admin/financing/loan-programs',
      },
      {
        title: 'Product types',
        icon: iconProductType,
        link: '/admin/financing/product-types',
      },
      {
        title: 'Products',
        icon: iconProduct,
        link: '/admin/financing/products',
      },
      {
        title: 'RV Matrix',
        muiIcon: <TableChartRounded sx={{ fill: 'white', fontSize: '20px', mr: '5px' }} />,
        link: '/admin/financing/rv-matrix',
      },
    ],
  },
  {
    id: 5,
    title: 'Insurance',
    icon: null,
    muiIcon: <CarCrash sx={{ fill: 'white', fontSize: '20px', mr: '5px' }} />,
    children: [
      {
        title: 'Insurance companies',
        muiIcon: <AccountBalanceRounded sx={{ fill: 'white', fontSize: '20px', mr: '5px' }} />,
        link: '/admin/insurance/companies',
      },
      {
        title: 'Risks',
        muiIcon: <Security sx={{ fill: 'white', fontSize: '20px', mr: '5px' }} />,
        link: '/admin/insurance/risks',
      },
    ],
  },
  {
    id: 6,
    title: 'Warranty',
    icon: iconCarWarranty,
    children: [
      {
        title: 'Products',
        muiIcon: <ViewListRounded sx={{ fill: 'white', fontSize: '20px', mr: '5px' }} />,
        link: '/admin/warranty/products',
      },
      {
        title: 'Components',
        muiIcon: <GridViewRounded sx={{ fill: 'white', fontSize: '20px', mr: '5px' }} />,
        link: '/admin/warranty/components',
      },
    ],
  },
]
